import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import AdditionalInfo from './AdditionalInfo';

const ProfileContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
`;

const ProfileItem = styled.div`
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
`;

const Profile: React.FC = () => {
    const user = useSelector((state: RootState) => state.auth.user);

    if (!user) {
        return null;
    }

    return (
        <ProfileContainer>
            <ProfileItem>Имя пользователя: {user.username}</ProfileItem>
            <ProfileItem>Email: {user.email}</ProfileItem>
            <AdditionalInfo />
        </ProfileContainer>
    );
};

export default Profile;