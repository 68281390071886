import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface UpdateUserPayload {
    country: string;
    city: string;
    birth_date: string;
    gender: string;
    weight: number;
    height: number;
}

export const updateUser = createAsyncThunk(
    'user/update',
    async (userData: UpdateUserPayload, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put('https://api.verdict.bot/api/user/update', userData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);