import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaArrowRight, FaCopy, FaShareAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import mockMessages from '../mock/messages';
import Loading from '../components/Loading'; // Импортируем глобальный компонент загрузки

interface MessageProps {
    isUser: boolean;
}

interface Message {
    user: boolean;
    text: string;
    type?: string;
    error?: string;
}

interface Product {
    id: number;
    name: string;
    price: number;
}

const ChatContainer = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    width: 650px;
    max-width: 100%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 196px);
    @media (max-width: 768px) {
        width: auto;
        margin: 0;
        border-radius: 0;
        padding: 10px;
        height: calc(100vh - 82px);
    }
`;

const ChatWindow = styled.div`
    padding-top: 10px;
    width: 100%;
    max-height: calc(100vh - 200px);
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
    flex-grow: 1;
    @media (max-width: 768px) {
        padding: 0px;
        padding-top: 10px;
        height: -webkit-fill-available;
        max-height: none;
    }
`;

const MessageContainer = styled.div<MessageProps>`
    display: flex;
    flex-direction: ${props => (props.isUser ? 'row-reverse' : 'row')};
    align-items: center;
    margin-bottom: 10px;
`;

const Avatar = styled.div<MessageProps>`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: url(${props => (props.isUser ? '' : '/img/avatar.png')});
    background-color: ${props => (props.isUser ? '#00796b' : '#ddd')};
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 10px;
`;

const Message = styled.div<MessageProps>`
    max-width: 70%;
    padding: 10px 15px;
    background-color: ${props => (props.isUser ? '#e8f5e9' : '#C1EEFE')};
    color: ${props => (props.isUser ? '#004d40' : '#37474f')};
    border-radius: 20px;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;

    & .part {
        display: block;
        margin-top: 10px;
    }

    & .part:first-child {
        margin-top: 0;
    }

    & ol, & ul {
        padding-left: 20px;
        margin-top: 5px;
    }

    & li {
        margin-bottom: 5px;
    }
`;

const InputContainer = styled.form`
    width: 100%;
    flex-direction: initial;
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: sticky;
    bottom: 0px;
    @media (max-width: 768px) {
        margin-top: 10px;
    }
`;

const Input = styled.textarea`
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-right: 10px;
    resize: none;
    overflow: auto;
    max-height: 150px;
    @media (max-width: 768px) {
        font-size: 16px;
        margin-right: 5px;
        -webkit-text-size-adjust: 100%;
    }

    &:focus {
        outline: none;
    }
`;

const Button = styled.button`
    background-color: #00796b;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #004d40;
    }

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const ButtonText = styled.span`
    display: none;

    @media (min-width: 769px) {
        display: inline;
        margin-left: 5px;
    }
`;

const LoadingMessage = styled.div`
    font-style: italic;
    color: #777;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &::before {
        content: '';
        width: 30px;
        height: 30px;
        background-image: url('/img/avatar.png');
        background-size: cover;
        border-radius: 50%;
        margin-right: 10px;
        margin-left: 10px;
    }
`;

const WelcomeMessage = styled.div`
    margin-top: 20px;
    white-space: pre-line;
`;

const PurchaseButton = styled.button`
    background-color: rgb(3, 115, 155);
    width: 100%;
    margin: 5px 0px;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: rgb(2, 91, 122);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: none;
    }

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const PurchaseContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #C1EEFE;
    color: #37474f;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 70%;
    margin-bottom: 10px;
`;

const PurchaseText = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    position: absolute;
    bottom: 7px;
    right: 7px;
`;

const ActionButton = styled.button`
    background-color: rgba(3, 115, 155, 0.4);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    padding: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: rgba(3, 115, 155, 1);

        & > svg {
            opacity: 1;
        }
    }

    & > svg {
        color: white;
        opacity: 0.8;
        transition: opacity 0.3s ease;
    }
`;

const PromocodeInput = styled.input`
    padding: 7px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-top: 7px;
    font-size: 12px;
    flex: 1;
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
`;

const PromocodeButton = styled.button`
    background-color: rgb(3, 115, 155);
    color: white;
    padding: 7px 10px;
    border: none;
    border-radius: 20px;
    margin-left: 4.5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: rgb(2, 91, 122);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: none;
    }
`;

const Chat: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loadingMessages, setLoadingMessages] = useState(true); // загрузка сообщений
    const [loadingSendMessage, setLoadingSendMessage] = useState(false); // загрузка при отправке сообщения
    const [products, setProducts] = useState<Product[]>([]);
    const [promocode, setPromocode] = useState('');
    const [promocodeError, setPromocodeError] = useState('');
    const token = useSelector((state: RootState) => state.auth.token);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();
    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const chatWindowRef = useRef<HTMLDivElement>(null);
    const isShareSupported = !!navigator.share;

    const initialMessages = [
        { user: false, text: 'Здравствуйте! Меня зовут Вердикт-бот, и я помогу вам с любыми вопросами, касающимися здоровья и медицины. Пожалуйста, помните, что моя помощь не заменяет консультацию врача.' },
        { user: false, text: 'Для получения наиболее точного ответа, пожалуйста, предоставьте как можно больше информации о вашем состоянии.\n- Опишите ваши симптомы.\n- Укажите, как давно они появились.\n- Сообщите, если вы уже принимали какие-либо лекарства или проходили лечение.' },
        { user: false, text: 'Пример запроса: "У меня болит горло и температура 37,5 уже 3 дня. Что это может быть и что мне делать?" или "У меня боль в груди, что это может быть?" или "Надо ли мне сдавать при повышенном холестерине анализ на расширенный липидный профиль и что это такое?"' }
    ];

    const handleResize = () => {
        if (inputRef.current && chatWindowRef.current) {
            const inputHeight = inputRef.current.scrollHeight;
            const windowHeight = window.innerHeight;
            chatWindowRef.current.style.height = `calc(${windowHeight}px - ${inputHeight}px - 196px)`;
        }
    };

    useEffect(() => {
        
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
        const target = e.target as HTMLTextAreaElement;
        target.style.height = 'auto';
        target.style.height = `${target.scrollHeight}px`;
        handleResize();
        // if (chatWindowRef.current) {
        //     const inputHeight = target.scrollHeight;
        //     const windowHeight = window.innerHeight;
        //     chatWindowRef.current.style.height = `calc(${windowHeight}px - ${inputHeight}px - 196px)`;
        // }
    };

    useEffect(() => {

        const promocodeMessage: Message = {
            user: false,
            text: 'Если у вас есть промокод, введите его сюда:',
            type: 'promocode'
        };

        const showMessagesSequentially = (messages: Message[]) => {
            messages.forEach((message, index) => {
                setTimeout(() => {
                    setMessages(prev => [...prev, message]);
                }, index * 1000); // Показ сообщений по очереди с интервалом в 1 секунду
            });
        };

        if (isDevelopment) {
            showMessagesSequentially([...initialMessages, promocodeMessage, ...mockMessages]);
            setLoadingMessages(false);
        } else {
            if (!token) {
                console.log('Нет токена, перенаправление на /login');
                navigate('/login');
                return;
            }

            axios.get('https://api.verdict.bot/chatbot/messages', {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(response => {
                    const loadedMessages = response.data.map((msg: any) => ({
                        user: msg.type === 'user',
                        text: msg.message
                    })).reverse();
                    if (loadedMessages.length === 0) {
                        showMessagesSequentially([...initialMessages, promocodeMessage]);
                    } else {
                        setMessages([...initialMessages, ...loadedMessages]);
                    }
                })
                .catch(error => {
                    console.error('Ошибка при загрузке сообщений:', error);
                })
                .finally(() => {
                    setLoadingMessages(false);
                });
        }
    }, [token, navigate, isDevelopment]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages, loadingMessages]);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (inputValue.trim() === '') return;

        const newMessage = { user: true, text: inputValue };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setLoadingSendMessage(true);

        const botResponse = isDevelopment
            ? { text: "Это ответ от бота в режиме разработки." }
            : axios.post('https://api.verdict.bot/chatbot/message', {
                text: inputValue,
                userId: user?.id,
                source: 'web'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(response => response.data)
                .catch(error => {
                    console.error('Ошибка при отправке сообщения:', error);
                    return { text: 'Ошибка при получении ответа от бота.' };
                });

        Promise.resolve(botResponse).then(botMessage => {
            if (botMessage.reply.phone_verification_required) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { user: false, text: 'Для продолжения необходимо подтвердить номер телефона.' }
                ]);
            } else if (botMessage.reply.purchase_required) {
                setProducts(JSON.parse(botMessage.reply.products));
            } else if (botMessage.reply.reply) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { user: false, text: botMessage.reply.reply }
                ]);
            } else if (botMessage.error) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { user: false, text: botMessage.reply.error }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { user: false, text: 'Произошла непредвиденная ошибка.' }
                ]);
            }
            setLoadingSendMessage(false);
        });

        setInputValue('');
    };

    const handleProductPurchase = async (productId: number) => {
        try {
            const response = await axios.post('https://api.verdict.bot/orders/create-order', {
                userId: user?.id,
                productId,
                paymentMethod: 'yookassa'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            window.location.href = response.data.paymentUrl;
        } catch (error) {
            console.error('Ошибка при создании заказа:', error);
            setMessages(prevMessages => [
                ...prevMessages,
                { user: false, text: 'Ошибка при создании заказа.' }
            ]);
        }
    };

    const handlePromocodeSubmit = async () => {
        try {
            // Устанавливаем состояние загрузки
            setLoadingMessages(true);

            // Отправка запроса на сервер для применения промокода
            const response = await axios.post('https://api.verdict.bot/auth/apply-promocode',
                { userId: user?.id, promocode },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Логируем успешный ответ сервера
            console.log('Ответ сервера при применении промокода:', response.data);

            // Если промокод успешно применен, заново загружаем сообщения с сервера
            const messagesResponse = await axios.get('https://api.verdict.bot/chatbot/messages', {
                headers: { Authorization: `Bearer ${token}` }
            });

            const loadedMessages = messagesResponse.data.map((msg: any) => ({
                user: msg.type === 'user',
                text: msg.message
            })).reverse();

            // Сохраняем initialMessages и добавляем загруженные сообщения
            if (loadedMessages.length === 0) {
                setMessages([...initialMessages, { user: false, text: 'Если у вас есть промокод, введите его сюда:', type: 'promocode' }]);
            } else {
                setMessages([...initialMessages, ...loadedMessages]);
            }

            // Очистка поля промокода и ошибки
            setPromocode('');
            setPromocodeError('');
            console.log('Сообщения успешно загружены после применения промокода:', loadedMessages);
        } catch (error) {
            // Обработка ошибки и установка сообщения об ошибке
            console.error('Ошибка при применении промокода:', error);
            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.error) {
                setPromocodeError(error.response.data.error);
            } else {
                setPromocodeError('Произошла ошибка при применении промокода.');
            }
        } finally {
            // Выключаем состояние загрузки только если ответ был успешен
            setLoadingMessages(false);
        }
    };




    const formatMessage = (text: string) => {
        const withEmojis = text
            .replace('Предполагаемая патология:', '🦠 Предполагаемая патология:')
            .replace('Что можно сделать:', '🧑🏻‍⚕️ Что можно сделать:')
            .replace('Мнение медицинского сообщества:', '🧑🏻‍⚕️ Мнение медицинского сообщества:')
            .replace('До приезда скорой:', '❗️ До приезда скорой:')
            .replace('Немедленно вызывайте скорую помощь!', '🚨 Немедленно вызывайте скорую помощь!')
            .replace('Вердикт:', '📝 Вердикт:');

        const boldedText = withEmojis
            .replace(/(🦠 Предполагаемая патология:)/g, '<strong>$1</strong>')
            .replace(/(🧑🏻‍⚕️ Что можно сделать:)/g, '<strong>$1</strong>')
            .replace(/(🧑🏻‍⚕️ Мнение медицинского сообщества:)/g, '<strong>$1</strong>')
            .replace(/(❗️ До приезда скорой:)/g, '<strong>$1</strong>')
            .replace(/(🚨 Немедленно вызывайте скорую помощь!)/g, '<strong>$1</strong>')
            .replace(/(📝 Вердикт:)/g, '<strong>$1</strong>');

        let formattedText = boldedText.split('\n').map(line => {
            if (/^\d+\.\s/.test(line)) {
                return `<li>${line.replace(/^\d+\.\s/, '')}</li>`;
            }
            if (/^\s*-\s/.test(line)) {
                return `<li>${line.replace(/^\s*-\s/, '')}</li>`;
            }
            if (line.trim() === '') {
                return '<br>';
            }
            return `<p style="margin: 0px;">${line}</p>`;
        }).join('');

        formattedText = formattedText.replace(/<\/li>(<li>)/g, '</li>$1');
        formattedText = formattedText.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');

        return formattedText;
    };

    const containsBoldText = (text: string) => {
        return /<strong>.*<\/strong>/.test(formatMessage(text));
    };

    const formatPlainText = (text: string) => {
        return text
            .replace('Предполагаемая патология:', '🦠 Предполагаемая патология:')
            .replace('Что можно сделать:', '🧑🏻‍⚕️ Что можно сделать:')
            .replace('Мнение медицинского сообщества:', '🧑🏻‍⚕️ Мнение медицинского сообщества:')
            .replace('До приезда скорой:', '❗️ До приезда скорой:')
            .replace('Немедленно вызывайте скорую помощь!', '🚨 Немедленно вызывайте скорую помощь!')
            .replace('Вердикт:', '📝 Вердикт:')
            .replace('    ', '');
    };

    const copyToClipboard = (text: string) => {
        const plainText = `${formatPlainText(text)}\n\nВердикт-бот — https://verdict.bot`;
        navigator.clipboard.writeText(plainText).then(() => {
            console.log('Скопировано в буфер обмена');
        }).catch(err => {
            console.error('Ошибка копирования текста:', err);
        });
    };

    const shareMessage = async (text: string) => {
        const plainText = `${formatPlainText(text)}\n\nВердикт-бот — https://verdict.bot`;
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Сообщение от Вердикт-бота',
                    text: plainText
                });
                console.log('Сообщение поделено');
            } catch (error) {
                console.error('Ошибка при попытке поделиться:', error);
            }
        } else {
            alert('Ваш браузер не поддерживает функцию общего доступа.');
            console.log('Web Share API не поддерживается в этом браузере');
        }
    };

    if (loadingMessages) {
        return (
            <ChatContainer>
                <ChatWindow>
                    <Loading />
                </ChatWindow>
                <InputContainer onSubmit={handleSubmit}>
                    <Input
                        ref={inputRef}
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        placeholder="Дождитесь загрузки..."
                        disabled
                        rows={1}
                    />
                    <Button type="submit">
                        <FaArrowRight />
                        <ButtonText>Отправить</ButtonText>
                    </Button>
                </InputContainer>
            </ChatContainer>
        );
    }

    return (
        <ChatContainer>
            <ChatWindow ref={chatWindowRef}>
                {messages.map((message, index) => (
                    <MessageContainer key={index} isUser={message.user}>
                        <Avatar isUser={message.user}>{message.user ? 'U' : ''}</Avatar>
                        <Message isUser={message.user}>
                            <div dangerouslySetInnerHTML={{ __html: formatMessage(message.text) }} />
                            {!message.user && containsBoldText(message.text) && (
                                <ButtonContainer>
                                    <ActionButton onClick={() => copyToClipboard(message.text)}>
                                        <FaCopy />
                                    </ActionButton>
                                    {isShareSupported && (
                                        <ActionButton onClick={() => shareMessage(message.text)}>
                                            <FaShareAlt />
                                        </ActionButton>
                                    )}
                                </ButtonContainer>
                            )}
                            {message.type === 'promocode' && (
                                <div>
                                    <PromocodeInput
                                        type="text"
                                        placeholder="EARLYBIRD"
                                        value={promocode}
                                        onChange={(e) => setPromocode(e.target.value)}
                                    />
                                    <PromocodeButton onClick={handlePromocodeSubmit}>
                                        Применить
                                    </PromocodeButton>
                                    {promocodeError && <p style={{ color: 'red', marginTop: '7px', marginBottom: '0px' }}>{promocodeError}</p>}
                                </div>
                            )}
                        </Message>
                    </MessageContainer>
                ))}

                {loadingSendMessage && <LoadingMessage>Анализирую...</LoadingMessage>}
                {products.length > 0 && (
                    <MessageContainer isUser={false}>
                        <Avatar isUser={false}></Avatar>
                        <PurchaseContainer>
                            <PurchaseText>
                                Для продолжения необходимо приобрести один из следующих продуктов:
                            </PurchaseText>
                            <div>
                                {products.map(product => (
                                    <PurchaseButton key={product.id} onClick={() => handleProductPurchase(product.id)}>
                                        {product.name} - {product.price} руб.
                                    </PurchaseButton>
                                ))}
                            </div>
                        </PurchaseContainer>
                    </MessageContainer>
                )}
            </ChatWindow>
            <InputContainer onSubmit={handleSubmit}>
                <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    placeholder="Введите сообщение..."
                    rows={1}
                    onInput={handleInput}
                />
                <Button type="submit">
                    <FaArrowRight />
                    <ButtonText>Отправить</ButtonText>
                </Button>
            </InputContainer>
        </ChatContainer>
    );
};

export default Chat;
