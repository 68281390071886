import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Products from '../components/Products';
import { FaFilePdf, FaEnvelope } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    max-width: 650px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    word-wrap: break-word;
    @media (max-width: 768px) {
        margin: 15px;
    }
`;

const TextContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Avatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
`;

const HeaderSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const VerdictName = styled.div`
    margin-bottom: 10px;
    width: 100%;
    font-size: 27px;
    font-weight: bold;
    color: #004d40;
`;

const Available = styled.div`
    font-size: 11px;
    font-style: italic;
    color: #004d40;
    margin-top: -7.5px;
    margin-left: 3.5px;
`;
const Description = styled.div`
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 8px;
    color: #004d40;
`;

const Disclaimer = styled.div`
    font-size: 11.5px;
    font-style: italic;
    color: #004d40;
    margin-bottom: 26.25px;
`;

const ProductSection = styled.div`
    width: 100%;
`;

const ProductDescription = styled.div`
    text-align: center;
    font-size: 15px;
    color: #00796b;
    margin: 10px 0px;
`;

const ProductList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0 0 26.25px 0;
    width: 100%;
`;

const ProductItem = styled.li`
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    margin-bottom: 15px;
`;

const LegalLinks = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;

    li {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    a {
        color: #00796b;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const ContactInfo = styled.div`
    font-size: 14px;
    color: #004d40;
    text-align: center;
    margin: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const EmailLink = styled.a`
    color: #00796b;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: 5px;

    &:hover {
        text-decoration: underline;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px 0px 25px 0px;
`;

const Button = styled.a`
    background-color: #00796b;
    width: 100%;
    text-align: center;
    color: #ffffff;
    border: none;
    padding: 11.25px 22.5px;
    font-size: 15px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;

    &:hover {
        background-color: #004d40;
        transform: translateY(-2px);
    }
`;

const BottomSpace = styled.div`
    height: 15px;
    @media (max-width: 768px) {
        height: 3px;
    }
`;

interface Product {
    id: number;
    name: string;
    description: string;
    price: number;
    type: string;
    duration: string;
    created_at: string;
}

const AboutPage: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    return (
        <>
            <Header />
            <Container>
                <TextContent>
                    <HeaderSection>
                        <Avatar src="img/avatar.png" alt="Вердикт-бот" />
                        <div>
                            <VerdictName>Вердикт-бот</VerdictName>
                            <Available>Доступен на сайте и в Telegram</Available>
                        </div>
                    </HeaderSection>
                    <Description>
                        Вердикт-бот создан, чтобы помочь вам определить возможные заболевания на основе ваших симптомов и предложить варианты дальнейших действий.
                    </Description><Description>
                        Также он может рассказать, какие анализы могут быть полезны для уточнения заболевания и как к ним подготовиться, а еще поможет с их интерпретацией и расскажет, что может быть причиной отклонения показателей от нормы.
                    </Description><Description>
                        Помимо этого он может дать информацию о здоровом образе жизни, профилактике заболеваний, диете и физическим нагрузкам, а также рассказать о способах улучшения качества жизни при наличии хронических заболеваний.
                    </Description>

                    <ProductDescription>
                Варианты подписки:
            </ProductDescription>
                    
                    <Products></Products>

                    {!isAuthenticated ? (
                        <ButtonWrapper>
                            <Button href="/register">Зарегистрироваться</Button>
                        </ButtonWrapper>
                    ) : (
                        <></>
                    )}
                    <Disclaimer>
                    Информация, предоставляемая Вердикт-ботом, носит рекомендательный характер и не заменяет профессиональную медицинскую консультацию. Всегда обращайтесь к квалифицированному врачу за медицинской помощью.
                    </Disclaimer>
                    <LegalLinks>
                        <li>
                            <FaFilePdf />
                            <a href="/legal/Agreement.pdf">Пользовательское соглашение</a>
                        </li>
                        <li>
                            <FaFilePdf />
                            <a href="/legal/Privacy_policy.pdf">Политика конфиденциальности</a>
                        </li>
                    </LegalLinks>
                    <ContactInfo>
                        Сервис разработан Зингерманом Петром Михайловичем, ИНН 772919421771.
                    </ContactInfo>
                    <ContactInfo>
                        Обратная связь:
                        <EmailLink href="mailto:info@verdict.bot">
                            <IconWrapper>
                                <FaEnvelope />
                            </IconWrapper>
                            info@verdict.bot
                        </EmailLink>
                    </ContactInfo>
                </TextContent>
            </Container>
            <BottomSpace></BottomSpace>
        </>
    )};

export default AboutPage;