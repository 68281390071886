import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Slider from 'react-slick';
import { FaGift } from 'react-icons/fa';
import Products from '../components/Products';

const PageContainer = styled.div`
    max-width: 700px;
    margin: 0 auto;
`;

const CustomSlider = styled(Slider)`
    margin-top: -25px;
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .slick-prev, .slick-next {
        z-index: 1;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }

    .slick-prev:before, .slick-next:before {
        line-height: 1.65;
    }

    .slick-dots {
        bottom: -30px;
    }

    @media (max-width: 768px) {
        margin-top: -4px;
        max-width: 100%;
        margin-bottom: 50px;
        justify-content: center;
    }
`;

const Promo = styled.div`
    background-color: #e0f2f1;
    color: #004d40;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    width: -webkit-fill-available;

    svg {
        height: 18px;
        width: auto;
        margin-right: 10px;
    }

    .text {
        display: inline;
        text-align: left;
    }

    .promo-code {
        margin-left: 5px;
        white-space: nowrap;
        margin-right: 0;
    }

    @media (max-width: 768px) {
        font-weight: normal;
        font-size: 12px;
        padding: 10px;
        margin: -7px 0px;

        svg {
            height: 35px;
            width: 35px;
            margin-right: 10px;
        }

    }
`;

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 650px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        margin: 15px;
    }
`;

const TextContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Avatar = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 15px;
`;

const HeaderSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const VerdictName = styled.div`
    margin-bottom: 10px;
    width: 100%;
    font-size: 27px;
    font-weight: bold;
    color: #004d40;
`;


const Available = styled.div`
    font-size: 11px;
    font-style: italic;
    color: #004d40;
    margin-top: -7.5px;
    margin-left: 3.5px;
`;

const WhatCanIHelp = styled.div`
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    font-size: 27px;
    font-weight: bold;
    color: #004d40;
    @media (max-width: 768px) {
        margin-top: 0px;
    }
`;


const ExamplesMobile = styled.div`
    display: none;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    
    color: #004d40;
    @media (max-width: 768px) {
        display: inline;
        font-size: 27px;
        font-weight: bold;
        display: inline;
        margin-top: -25px;
    }
`;

const ExamplesDesktop = styled.div`
    display: none;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    
    color: #004d40;
    @media (max-width: 768px) {
        display: inline;
        font-size: 27px;
        font-weight: bold;
        display: inline;
        margin-top: 0px;
    }
`;

const ProductDescription = styled.div`
margin-top: 0px;
text-align: center;
margin-bottom: 10px;
width: 100%;
font-size: 27px;
font-weight: bold;
color: #004d40;
@media (max-width: 768px) {
    display: inline;
    margin-top: 0px;
}
`;

const Description = styled.div`
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #004d40;

    a {
        color: #004d40;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    // @media (max-width: 768px) {
    //     display: none;
    // }
`;

const Button = styled.a`
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;

    &:hover {
        background-color: #004d40;
        transform: translateY(-2px);
    }
`;

const MobileButton = styled.a`
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
    width: -webkit-fill-available;
    margin: 15px 0px 10px 0px;
    display: none;

    @media (max-width: 768px) {
        display: inline-block;
    }

    &:hover {
        background-color: #004d40;
        transform: translateY(-2px);
    }
`;

const BottomButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    @media (max-width: 768px) {
        margin: 10px 0px;
    }
`;

const BottomButton = styled.a`
    width: 100%;
    text-align: center;
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;

    &:hover {
        background-color: #004d40;
        transform: translateY(-2px);
    }
`;

const Screenshot = styled.img`
    // width: 100%;
    // max-width: 250px;
    border-radius: 10px;
    display: block !important;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;

    // @media (max-width: 768px) {
    //     display: none;
    // }
`;

const Screenshot2 = styled.img`
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`;

const Disclaimer = styled.div`
    font-size: 12px;
    font-style: italic;
    color: #004d40;
`;

const TileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
`;

const Tile = styled.div`
    background-color: #e0f2f1;
    border-radius: 10px;
    padding: 15px;
    width: calc(50% - 35px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Icon = styled.img`
    width: 40px;
    height: 40px;
`;

const TileDescription = styled.div`
    font-size: 12.9px;
    color: #004d40;
`;

const BottomSpace = styled.div`
    height: 15px;
    @media (max-width: 768px) {
        height: 3px;
    }
`;

const HomePage: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <PageContainer>
            <Header />
            <Container>
                <TextContent>
                    <HeaderSection>
                        <Avatar src="img/avatar.png" alt="Вердикт-бот" />
                        <div>
                            <VerdictName>Вердикт-бот</VerdictName>
                            <Available>Доступен на сайте и в Telegram</Available>
                        </div>
                    </HeaderSection>

                    <Description>
                        Вас приветствует Вердикт-бот — ваш надёжный источник информации, тщательно переведенной на русский язык из медицинских источников со всего мира.
                    </Description>
                    <Description>
                        Регистрируйтесь и получайте прямо сейчас промокод на бесплатную консультацию. Акция действует до конца июля.
                    </Description>
                    <ButtonWrapper>
                        <Button id="free" href="/register">Получить бесплатную консультацию</Button>
                    </ButtonWrapper>
                    <Description>
                        Опишите как можно точнее ваше недомогание и задавайте вопросы. В научной среде ваши симптомы уже хорошо изучены, и существуют детально проработанные методы диагностики и анализа, которые сопутствуют определенным заболеваниям.
                    </Description>
                    <Description>
                        Окончательные выводы в любом случае делает ваш лечащий врач.
                    </Description>
                    <Description>
                    </Description>
                    {/* <Description>
                        Забудьте об ожидании в очередях и неэффективных интернет-советах. Я доступен <b>24/7</b> и всегда готов помочь вам, когда это необходимо.
                    </Description> */}
                    {/* <ButtonWrapper>
                        <MobileButton id="create" href="/register">Зарегистрироваться</MobileButton>
                    </ButtonWrapper> */}
                </TextContent>
                <ExamplesMobile>Примеры диалога</ExamplesMobile>
{/*                 
                <ExamplesDesktop>Примеры диалога</ExamplesDesktop> */}
                <CustomSlider {...settings}>
                    
                    <Screenshot src="img/screenshot1.png" alt="Вердикт-бот скриншот 1" />
                    <Screenshot src="img/screenshot2.png" alt="Вердикт-бот скриншот 2" />
                    <Screenshot src="img/screenshot3.png" alt="Вердикт-бот скриншот 3" />
                    <Screenshot src="img/screenshot4.png" alt="Вердикт-бот скриншот 4" />
                </CustomSlider>
                
                {/* <Promo>
                    <FaGift />
                    <div className="text">
                        Акция до конца июля! 1-й час консультаций бесплатно по промокоду
                        <span className="promo-code"><b>FREE1</b></span>.
                    </div>
                </Promo> */}
                <MobileButton id="create" href="/register">Зарегистрироваться</MobileButton>
                <WhatCanIHelp>Для чего нужен Вердикт-бот</WhatCanIHelp>
                <TileContainer>
                    <Tile>
                        <Icon src="img/icon1.png" alt="Icon 1" />
                        <TileDescription>
                            Определить возможные заболевания и предоставить варианты дальнейших действий.
                        </TileDescription>
                    </Tile>
                    <Tile>
                        <Icon src="img/icon2.png" alt="Icon 2" />
                        <TileDescription>
                            Предоставить информацию о возможных схемах приема лекарств, их эффективности и взаимодействии.
                        </TileDescription>
                    </Tile>
                    <Tile>
                        <Icon src="img/icon3.png" alt="Icon 3" />
                        <TileDescription>
                            Подсказать, какие анализы могут быть полезны для уточнения заболевания и как к ним подготовиться.
                        </TileDescription>
                    </Tile>
                    <Tile>
                        <Icon src="img/icon4.png" alt="Icon 4" />
                        <TileDescription>
                            Помочь интерпретировать результаты анализов и объяснить, что может быть причиной отклонений от нормы.
                        </TileDescription>
                    </Tile>
                    <Tile>
                        <Icon src="img/icon5.png" alt="Icon 5" />
                        <TileDescription>
                            Рассказать о здоровом образе жизни, профилактике заболеваний, диете и физическим нагрузкам.
                        </TileDescription>
                    </Tile>
                    <Tile>
                        <Icon src="img/icon6.png" alt="Icon 6" />
                        <TileDescription>
                            Предложить эффективные способы улучшения качества жизни при наличии хронических заболеваний.
                        </TileDescription>
                    </Tile>
                </TileContainer>
                <BottomButtonWrapper>
                    <BottomButton id="create-and-ask" href="/register">Зарегистрироваться и начать диалог</BottomButton>
                </BottomButtonWrapper>
                <ProductDescription id="Sub">
                    Варианты подписки:
                </ProductDescription>
                <Products></Products>
                <Disclaimer>Информация, предоставляемая Вердикт-ботом, носит рекомендательный характер и не заменяет профессиональную медицинскую консультацию. Всегда обращайтесь к квалифицированному врачу за медицинской помощью.</Disclaimer>
            </Container>
            <BottomSpace></BottomSpace>
        </PageContainer>

    );
};

export default HomePage;