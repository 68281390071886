import React from 'react';
import Header from '../components/Header';
import VerifyEmail from '../components/VerifyEmail';

const VerifyEmailPage: React.FC = () => {
    return (
        <div>
            <Header />
            <VerifyEmail />
        </div>
    );
};

export default VerifyEmailPage;