import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import AuthPopup from '../components/AuthPopup'
import axios from 'axios';


const ProductSection = styled.div`
    width: 100%;
`;

const ProductList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0 0 26.25px 0;
    width: 100%;
`;

const ProductItem = styled.li`
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    margin-bottom: 15px;
`;

interface Product {
    id: number;
    name: string;
    description: string;
    price: number;
    type: string;
    duration: string;
    created_at: string;
}


const Products: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const user = useSelector((state: RootState) => state.auth.user);
    const token = useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        fetch('https://api.verdict.bot/products')
            .then(response => response.json())
            .then(data => setProducts(data))
            .catch(error => setError('Ошибка при загрузке продуктов.'));
    }, []);

    const handleProductPurchase = async (productId: number) => {
        if (!isAuthenticated) {
            setShowPopup(true);
            return;
        }

        try {
            const response = await axios.post('https://api.verdict.bot/orders/create-order', {
                userId: user?.id,
                productId,
                paymentMethod: 'yookassa'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            window.location.href = response.data.paymentUrl;
        } catch (error) {
            console.error('Ошибка при создании заказа:', error);
            // setMessages(prevMessages => [
            //     ...prevMessages,
            //     { user: false, text: 'Ошибка при создании заказа.' }
            // ]);
        }
    };

    return (
        <ProductSection>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ProductList>
                {products.map(product => (
                    <ProductItem onClick={() => handleProductPurchase(product.id)} key={product.id}>
                        <span>{product.name}</span>
                        <span>{product.price} руб.</span>
                    </ProductItem>
                ))}
                {showPopup && (
                    <AuthPopup
                        message="Для приобретения подписки войдите или создайте аккаунт"
                        onClose={() => setShowPopup(false)}
                    />
                )}
            </ProductList>
        </ProductSection>)
};


export default Products;