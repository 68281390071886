import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { login } from '../redux/actions/authActions';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    max-width: 845px;
    width: 640px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        margin: 15px;
        width: auto;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 20px;
`;

const Label = styled.label`
    position: absolute;
    z-index: 2;
    top: -7.5px;
    left: 8px;
    background-color: white;
    padding: 0px 2px;
    font-size: 12px;
`;

const Input = styled.input`
    padding: 15px;
    font-size: 18px;
    border: 1px solid #00796b;
    border-radius: 5px;
    width: -webkit-fill-available;
`;

const StyledPhoneInput = styled(PhoneInput)`
    .form-control {
        width: 100%;
        padding: 15px;
        font-size: 18px;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 1px solid #00796b;
        height: 53px;
    }
    .flag-dropdown {
        display: none;
    }
    .selected-flag {
        pointer-events: none;
    }
`;

const Button = styled.button`
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 10.25px 32.125px;;
    font-size: 17px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #004d40;
    }
`;

const Divider = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    color: #ddd;

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ddd;
    }

    &::before {
        margin-right: 10px;
    }

    &::after {
        margin-left: 10px;
    }
`;

const Message = styled.p<{ isError: boolean }>`
    text-align: center;
    color: ${props => (props.isError ? 'red' : 'green')};
`;

const Login: React.FC = () => {
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isPhoneNumber, setIsPhoneNumber] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?7';
        script.async = true;
        script.setAttribute('data-telegram-login', 'verdictbot_bot');
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-auth-url', 'https://verdict.bot/api/auth/telegram');
        script.setAttribute('data-request-access', 'write');
        document.getElementById('telegram-login')?.appendChild(script);
    }, []);

    const handleIdentifierChange = (value: string) => {
        const hasLetters = /[a-zA-Z]/.test(value);
    
        if (hasLetters) {
            setIdentifier(value);
            setIsPhoneNumber(false);
            return;
        }
    
        const cleanedValue = value.replace(/[^\d+]/g, '');
    
        const isPhone = cleanedValue.length <= 12 && 
                        ((/^[789]/.test(cleanedValue) && cleanedValue.length <= 11) || 
                        (cleanedValue.startsWith('+7') && cleanedValue.slice(2).length <= 10));
    
        if (isPhone) {
            let phone = cleanedValue;
            if (phone.startsWith('8') || phone.startsWith('7')) {
                phone = phone.slice(1);
            } else if (phone.startsWith('+7')) {
                phone = phone.slice(2);
            }
            setIdentifier(`+7${phone}`);
            setIsPhoneNumber(true);
        } else {
            setIdentifier(value);
            setIsPhoneNumber(false);
        }
    };
    

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await dispatch(login({ identifier: identifier.toLowerCase(), password })).unwrap();
            setMessage('Вход выполнен успешно.');
            setIsError(false);
            navigate('/chat');
        } catch (error: any) {
            if (error.data && error.data.error) {
                setMessage(error.data.error);
            } else {
                setMessage(error.message || 'Ошибка при входе. Пожалуйста, попробуйте снова.');
            }
            setIsError(true);
        }
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <InputContainer>
                    <Label>Имя пользователя или телефон</Label>
                    {isPhoneNumber ? (
                        <StyledPhoneInput
                            country={'ru'}
                            value={identifier}
                            onChange={handleIdentifierChange}
                            onlyCountries={['ru']}
                            placeholder="+7 (___) ___-__-__"
                            inputStyle={{
                                marginBottom: '0px'
                            }}
                            inputProps={{
                                required: true,
                                autoFocus: true
                            }}
                        />
                    ) : (
                        <Input
                            type="text"
                            autoFocus
                            placeholder="Login или 89991234567"
                            value={identifier}
                            onChange={(e) => handleIdentifierChange(e.target.value)}
                        />
                    )}
                </InputContainer>
                <InputContainer>
                    <Label>Пароль</Label>
                    <Input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </InputContainer>
                {message && <Message isError={isError}>{message}</Message>}
                <Button type="submit">Войти</Button>
            </Form>
            <Divider>ИЛИ</Divider>
            <div id="telegram-login"></div>
            
        </Container>
    );
};

export default Login;