import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../redux/store';
import { performLogout } from '../redux/actions/authActions';
import { FaHome, FaInfoCircle, FaSignOutAlt } from 'react-icons/fa';
import { FaTelegram } from "react-icons/fa6";

const HeaderContainer = styled.header`
    background-color: #ffffff;
    padding: 10px;
    color: #00796b;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-top: 15px;
    @media (max-width: 768px) {
        margin-top: 0px;
        border-radius: 0;
        width: -webkit-fill-available;
    }
`;

const Nav = styled.nav`
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    li {
        display: inline;
        margin-right: 10px;

        @media (max-width: 768px) {
            margin-right: 0;
        }
    }
    a {
        color: #00796b;
        background-color: #eeeeee80;
        text-decoration: none;
        font-size: 20px;
        padding: 10px 20px;
        border-radius: 10px;
        transition: background-color 0.3s ease, transform 0.3s ease;

        @media (max-width: 768px) {
            padding: 10px 16px;
            background-color: unset;
        }
    }

    a, button {
        margin: 0px 3px;

        @media (max-width: 768px) {
            background-color: #eeeeee80;

            .nav-icon {
                padding: 1.75px 0px;
            }
        }

        &:hover {
            background-color: #e0f7fa;
        }
        cursor: pointer;
    }

    a.active {
        font-weight: bold;
        background-color: #e0f7fa;
    }
`;

const LogoutButton = styled.button`
    color: #00796b;
    text-decoration: none;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline;
    margin-right: 10px;
    border: 0px;
    background-color: #eeeeee75;

    @media (max-width: 768px) {
        background-color: unset;
        padding: 10px 16px;
    }
`;

const NavLinkStyled = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-icon {
        display: none;
    }

    .nav-text {
        display: inline;
    }

    @media (max-width: 768px) {
        .nav-icon {
            display: inline;
            width: 22px;
        }

        .nav-text {
            display: none;
        }
    }
`;

const LogoutButtonStyled = styled(LogoutButton)`
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-icon {
        display: none;
    }

    .nav-text {
        display: inline;
    }

    @media (max-width: 768px) {
        .nav-icon {
            display: inline;
        }

        .nav-text {
            display: none;
        }
    }
`;

const Header: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(performLogout());
        navigate('/');
    };

    return (
        <HeaderContainer>
            <Nav>
                <ul>
                    <li>
                        <NavLinkStyled to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
                            <span className="nav-text">Главная</span>
                            <FaHome className="nav-icon" />
                        </NavLinkStyled>
                    </li>
                    {(isAuthenticated ) ? (
                        <>
                            <li>
                                <NavLinkStyled to="/chat" className={({ isActive }) => (isActive ? 'active' : '')}>
                                    <span className="nav-text">Чат</span>
                                    <img className="nav-icon" src="/img/favicons/favicon-192x192.png" alt="Чат" />
                                </NavLinkStyled>
                            </li>
                            <li>
                                <NavLinkStyled to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>
                                    <span className="nav-text">О проекте</span>
                                    <FaInfoCircle className="nav-icon" />
                                </NavLinkStyled>
                            </li>
                            <li>
                                <NavLinkStyled to="https://t.me/verdictbot_bot">
                                    <span className="nav-text">Телеграм-бот</span>
                                    <FaTelegram className="nav-icon" />
                                </NavLinkStyled>
                            </li>
                            <li>
                                <LogoutButtonStyled onClick={handleLogout}>
                                    <span className="nav-text">Выход</span>
                                    <FaSignOutAlt className="nav-icon" />
                                </LogoutButtonStyled>
                            </li>
                        </>
                    ) : (
                        <>
                            <li>
                                <NavLink to="/login" className={({ isActive }) => (isActive ? 'active' : '')}>
                                    Вход
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/register" className={({ isActive }) => (isActive ? 'active' : '')}>
                                    Регистрация
                                </NavLink>
                            </li>
                            <li>
                                <NavLinkStyled to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>
                                    <span className="nav-text">О проекте</span>
                                    <FaInfoCircle className="nav-icon" />
                                </NavLinkStyled>
                            </li>
                        </>
                    )}
                </ul>
            </Nav>
        </HeaderContainer>
    );
};

export default Header;
