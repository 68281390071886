import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const VerifyEmail: React.FC = () => {
    const location = useLocation();
    const [message, setMessage] = useState('');

    const verifyEmail = async () => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            try {
                await axios.get(`/api/auth/verify-email?token=${token}`);
                setMessage('Email verification successful.');
            } catch (error) {
                setMessage('Email verification failed. Please try again.');
            }
        } else {
            setMessage('Invalid verification link.');
        }
    };

    useEffect(() => {
        verifyEmail();
    }, []);

    return (
        <div>
            <h2>Email Verification</h2>
            <p>{message}</p>
        </div>
    );
};

export default VerifyEmail;
