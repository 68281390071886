import React from 'react';
import Header from '../components/Header';
import Login from '../components/Login';
import styled from 'styled-components';

const PageContainer = styled.div`
    max-width: 700px;
`;


const LoginPage: React.FC = () => {
    return (
        <PageContainer>
            <Header />
            <Login />
        </PageContainer>
    );
};

export default LoginPage;