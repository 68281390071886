import React from 'react';
import Header from '../components/Header';
import styled from 'styled-components';
import Profile from '../components/Profile';

const PageContainer = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 35px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    max-width: 845px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PageTitle = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #00796b;
    margin-bottom: 20px;
`;

const ProfilePage: React.FC = () => {
    return (
        <PageContainer>
            <Header />
            <PageTitle>Профиль</PageTitle>
            <Profile />
        </PageContainer>
    );
};

export default ProfilePage;