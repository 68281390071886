import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import ChatPage from './pages/ChatPage';
import ProfilePage from './pages/ProfilePage';
import AboutPage from './pages/AboutPage';
import TelegramAuth from './pages/TelegramAuth';
import Loading from './components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './redux/store';
import { setToken, setUser } from './redux/reducers/authReducer';
import GTM from './containers/GTM'; // Google Tag Manager

const App: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');

        console.log('Извлеченный токен:', token);
        console.log('Извлеченный пользователь:', user);

        if (token && user) {
            console.log('Диспетчеризация setToken и setUser');
            dispatch(setToken(token));
            dispatch(setUser(JSON.parse(user)));
        } else {
            console.log('Токен или пользователь отсутствуют');
        }

        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        console.log('isAuthenticated:', isAuthenticated);
    }, [isAuthenticated]);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <GTM />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/verify-email" element={<VerifyEmailPage />} />
                <Route path="/telegram-auth" element={<TelegramAuth />} />
                <Route path="/about" element={<AboutPage />} />
                <Route 
                    path="/chat" 
                    element={isAuthenticated || isDevelopment ? <ChatPage /> : <Navigate to="/login" />} 
                />
                <Route 
                    path="/profile" 
                    element={isAuthenticated ? <ProfilePage /> : <Navigate to="/login" />} 
                />
                <Route
                    path="*"
                    element={<Navigate to={isAuthenticated ? (localStorage.getItem('lastVisited') || '/chat') : '/login'} />}
                />
            </Routes>
        </>
    );
};

const AppWithRouter: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWithRouter;
