import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { updateUser } from '../redux/actions/userActions';
import styled from 'styled-components';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const Input = styled.input`
    padding: 15px;
    font-size: 18px;
    margin-bottom: 20px;
    border: 1px solid #00796b;
    border-radius: 5px;
    width: 100%;
`;

const Button = styled.button`
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #004d40;
        transform: translateY(-2px);
    }
`;

const AdditionalInfo: React.FC = () => {
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [birth_date, setBirthDate] = useState('');
    const [gender, setGender] = useState('');
    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const dispatch: AppDispatch = useDispatch();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(updateUser({ country, city, birth_date, gender, weight, height }));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h2>Дополнительная информация</h2>
            <Input type="text" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Страна" />
            <Input type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Город" />
            <Input type="date" value={birth_date} onChange={(e) => setBirthDate(e.target.value)} placeholder="Дата рождения" />
            <Input type="text" value={gender} onChange={(e) => setGender(e.target.value)} placeholder="Пол" />
            <Input type="number" value={weight} onChange={(e) => setWeight(Number(e.target.value))} placeholder="Вес" />
            <Input type="number" value={height} onChange={(e) => setHeight(Number(e.target.value))} placeholder="Рост" />
            <Button type="submit">Сохранить</Button>
        </Form>
    );
};

export default AdditionalInfo;