import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div<{ fullScreen?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => (props.fullScreen ? '100vh' : '100%')};
    width: ${props => (props.fullScreen ? '100vw' : '100%')};
    position: ${props => (props.fullScreen ? 'fixed' : 'absolute')};
    top: 0;
    left: 0;
    background-color: ${props => (props.fullScreen ? 'rgba(255, 255, 255, 0.8)' : 'transparent')};
    z-index: 9999;
`;

const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #00796b;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: ${spin} 2s linear infinite;
`;

interface LoadingProps {
    fullScreen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ fullScreen = false }) => (
    <LoaderContainer fullScreen={fullScreen}>
        <Spinner />
    </LoaderContainer>
);

export default Loading;