import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Chat from '../components/Chat';
// import Loading from '../components/Loading';
import styled from 'styled-components';

const ChatPageContainer = styled.div`
    // display: flex;
    // flex-direction: column;
    // height: 100vh;
`;

const ChatPage: React.FC = () => {
    // const [loading, setLoading] = useState(true);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const navigate = useNavigate();

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 2000); // Эмуляция проверки аутентификации или загрузки данных

    //     return () => clearTimeout(timer);
    // }, []);

    useEffect(() => {
        if (!isAuthenticated && !isDevelopment/*&& !loading*/) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate/*, loading*/]);

    // if (loading) {
    //     return <Loading fullScreen />;
    // }

    return (
        <ChatPageContainer>
            <Header />
            <Chat />
        </ChatPageContainer>
    );
};

export default ChatPage;