import React, { useEffect, useState } from 'react';
import { AppDispatch } from '../redux/store';
import axios from 'axios';
import styled from 'styled-components';
import PinInput from 'react-pin-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../redux/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { register } from '../redux/actions/authActions';
import { FaGift } from 'react-icons/fa';

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    width: 640px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        margin: 15px;
        width: auto;
    }
`;

const Promo = styled.div`
    background-color: #e0f2f1;
    color: #004d40;
    border-radius: 10px;
    
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    width: -webkit-fill-available;
    margin-top: -40px;
    margin-bottom: 60px;

    svg {
        height: 18px;
        width: auto;
        margin-right: 10px;
    }

    .text {
        text-align: center;
        display: inline;
        width: 100%;
    }

    .promo-code {
        margin-left: 5px;
        white-space: nowrap;
        margin-right: 0;
    }

    @media (max-width: 768px) {
        font-weight: normal;
        font-size: 12px;
        padding: 10px;
        margin: -40px 0px 60px 0px;

        svg {
            height: 35px;
            width: 35px;
            margin-right: 10px;
        }

    }
`;

const PromoDisclaimer = styled.p`
font-size: 14px;
color: #004d40;
text-align: center;
margin: -55px 0px 55px;

@media (max-width: 768px) {
    font-weight: normal;
    font-size: 12px;
}
`;

const Form = styled.form`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 20px;
`;

const Label = styled.label`
    position: absolute;
    z-index: 2;
    top: -7.5px;
    left: 8px;
    background-color: white;
    padding: 0px 2px;
    font-size: 12px;
`;

const Input = styled.input`
    padding: 15px;
    font-size: 18px;
    border: 1px solid #00796b;
    border-radius: 5px;
    width: -webkit-fill-available;
`;

const StyledPhoneInput = styled(PhoneInput)`
    .form-control {
        width: 100%;
        padding: 15px;
        font-size: 18px;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 1px solid #00796b;
        height: 53px;
    }
    .flag-dropdown {
        display: none;
    }
    .selected-flag {
        pointer-events: none;
    }
`;

const Button = styled.button`
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 10.25px 32.125px;;
    font-size: 17px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #004d40;
    }
`;

const TextButton = styled.button`
    background: none;
    color: #00796b;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 10px;

    &:hover {
        text-decoration: none;
    }
`;

const Message = styled.p<{ isError: boolean }>`
    color: ${props => (props.isError ? 'red' : 'green')};
    margin-top: 0px;
    margin-bottom: 45px;
    text-align: center;
`;

const PrivacyText = styled.p`
    font-size: 14px;
    color: #666;
    text-align: center;
    margin: -35px 10px 20px;

    a {
        color: #00796b;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const UseTelegram = styled.p`
    font-size: 14px;
    color: #666;
    text-align: center;
    margin: -5px 0px 15px;
`;

const Divider = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    color: #ddd;

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ddd;
    }

    &::before {
        margin-right: 10px;
    }

    &::after {
        margin-left: 10px;
    }
`;

const Subscription = styled.p`
font-size: 14px;
color: #666;
text-align: center;
margin: 15px 0px 0px;

a {
    color: #00796b;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
`;

const Register: React.FC = () => {
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [promocode, setPromocode] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [timer, setTimer] = useState(50);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setPromocode('FREE1');
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?7';
        script.async = true;
        script.setAttribute('data-telegram-login', 'verdictbot_bot');
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-auth-url', 'https://verdict.bot/api/auth/telegram');
        script.setAttribute('data-request-access', 'write');
        document.getElementById('telegram-login')?.appendChild(script);
    }, []);

    useEffect(() => {
        if (isCodeSent) {
            const interval = setInterval(() => {
                setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isCodeSent]);

    const handlePhoneNumberChange = (value: string) => {
        let phone = value.replace(/\D/g, '');
        if (phone.startsWith('8')) {
            phone = phone.slice(1);
        } else if (phone.startsWith('7')) {
            phone = phone.slice(1);
        } else if (phone.startsWith('+7')) {
            phone = phone.slice(2);
        }
        setPhoneNumber(`+7${phone}`);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await dispatch(register({ username, phoneNumber, password, promocode })).unwrap();
            setMessage('Пожалуйста, проверьте ваш телефон и введите код подтверждения. Если вы испытываете затруднения при регистриации с помощью СМС, используйте Telegram для создания аккаунта.');
            setIsError(false);
            setIsCodeSent(true);
        } catch (error: any) {
            console.log('Ошибка при handleSubmit:', error);
            if (error.data && error.data.error) {
                setMessage(error.data.error);
            } else {
                setMessage('Ошибка при регистрации. Пожалуйста, попробуйте снова.');
            }
            setIsError(true);
        }
    };

    const handleResendCode = async () => {
        if (timer === 0) {
            try {
                await axios.post('https://api.verdict.bot/auth/resend-code', { phoneNumber });
                setMessage('Код подтверждения отправлен повторно.');
                setIsError(false);
                setTimer(50);
            } catch (error: any) {
                setMessage('Ошибка при повторной отправке кода. Пожалуйста, попробуйте снова.');
                setIsError(true);
            }
        }
    };

    const handleCodeComplete = async (value: string) => {
        try {
            const response = await axios.post('https://api.verdict.bot/auth/verify-code', { phoneNumber, code: value });
            const { token, user } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(setToken(token));
            dispatch(setUser(user));
            setMessage('Номер телефона успешно подтвержден.');
            setIsError(false);
            navigate('/chat');
        } catch (error: any) {
            setMessage('Неверный код подтверждения. Пожалуйста, попробуйте снова.');
            setIsError(true);
        }
    };

    

    return (
        <Container>
            <UseTelegram>
                Если у вас нет российского номера, используйте для регистрации Telegram. Будет примненен промокод FREE1.
            </UseTelegram>
            <div id="telegram-login"></div>
            {!isCodeSent ? (
                <Form onSubmit={handleSubmit}>
                    <InputContainer>
                        <Label>Телефон</Label>
                        <StyledPhoneInput
                            country={'ru'}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            onlyCountries={['ru']}
                            placeholder="+7 (___) ___-__-__"
                            inputStyle={{
                                marginBottom: '0px'
                            }}
                            inputProps={{
                                required: true,
                                autoFocus: true
                            }}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label>Логин</Label>
                        <Input
                            type="text"
                            placeholder=""
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label>Пароль</Label>
                        <Input
                            type="password"
                            placeholder=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label>Промокод</Label>
                        <Input
                            type="text"
                            placeholder="EARLYBIRD"
                            style={{textTransform: "uppercase"}}
                            value={promocode}
                            onChange={(e) => setPromocode(e.target.value)}
                        />
                    </InputContainer>
                    <Message isError={isError}>{message}</Message>

                    <Promo>
                    {/* <FaGift /> */}
                    <div className="text">
                        Акция!<br></br>
                        Часовая консультация в июле бесплатна по промокоду<span className="promo-code"><b>FREE1</b></span>.<br></br>
                        Консультация начнётся сразу после регистрации. 
                    </div>
                    </Promo>
                    {/* <PromoDisclaimer></PromoDisclaimer> */}
                    <PrivacyText>
                        Регистрируясь, я подтверждаю, что ознакомлен и согласен с
                        <a href="/legal/Privacy_policy.pdf" target="_blank" rel="noopener noreferrer"> политикой конфиденциальности</a> и
                        <a href="/legal/Agreement.pdf" target="_blank" rel="noopener noreferrer"> офертой</a> сервиса Вердикт-бот.
                    </PrivacyText>
                    <Button type="submit">Зарегистрироваться</Button>
                    {/* <Subscription>После окончания консультации оформить подписку вы сможете в удобное для для вас время. <a href="/#Sub">Информация о подписках.</a></Subscription> */}
                </Form>
            ) : (
                <>
                    <Message isError={isError}>{message}</Message>
                    <PinInput
                        length={4}
                        initialValue=""
                        onChange={() => {}}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: '10px' }}
                        inputStyle={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '5px',
                            border: '1px solid #00796b',
                            margin: '0 5px'
                        }}
                        inputFocusStyle={{ borderColor: '#004d40' }}
                        onComplete={(value) => handleCodeComplete(value)}
                    />
                    <TextButton onClick={handleResendCode} disabled={timer > 0}>
                        {timer > 0 ? `Отправить код повторно (${timer})` : 'Отправить код повторно'}
                    </TextButton>
                    <Button onClick={() => handleCodeComplete((document.querySelector('input') as HTMLInputElement).value)}>Подтвердить номер</Button>
                </>
            )}
        </Container>
    );
};

export default Register;