import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM_ID = 'GTM-KVR89MQC';

const GTM: React.FC = () => {
    useEffect(() => {
        TagManager.initialize({ gtmId: GTM_ID });
    }, []);

    return null;
};

export default GTM;
