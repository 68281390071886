import React from 'react';
import Header from '../components/Header';
import Register from '../components/Register';
import styled from 'styled-components';

const PageContainer = styled.div`
    max-width: 700px;
`;

const BottomSpace = styled.div`
    height: 15px;
    @media (max-width: 768px) {
        height: 3px;
    }
`;

const RegisterPage: React.FC = () => {
    return (
        <PageContainer>
            <Header />
            <Register />
            <BottomSpace></BottomSpace>
        </PageContainer>
    );
};

export default RegisterPage;