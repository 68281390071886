import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store'; // Импортируйте AppDispatch из вашего store
import { useNavigate, useLocation } from 'react-router-dom';
import { telegramLogin } from '../redux/actions/authActions';

const TelegramAuth: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        const username = searchParams.get('username');
        const photo_url = searchParams.get('photo_url');
        const user_id = searchParams.get('user_id');

        if (token && username && user_id) {
            dispatch(telegramLogin({ token, username, photo_url, user_id }))
                .unwrap()
                .then(() => {
                    localStorage.setItem('lastVisited', '/chat');
                    navigate('/chat');
                })
                .catch((error) => {
                    console.error('Ошибка при входе через Telegram:', error);
                });
        }
    }, [dispatch, navigate, location]);

    return null;
};

export default TelegramAuth;
