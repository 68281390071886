import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    @media (max-width: 768px) {
        z-index: 5
    }
`;

const PopupContent = styled.div`
    margin: auto 30px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Button = styled(Link)`
    display: block;
    background-color: #00796b;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    margin: 10px;

    &:hover {
        background-color: #004d40;
    }
`;

const ButtonWrapper = styled.div`
    margin: 15px 0px;
`;

const AuthPopup = ({ message, onClose }: { message: string, onClose: () => void }) => {
    return (
        <PopupContainer onClick={onClose}>
            <PopupContent onClick={(e) => e.stopPropagation()}>
                <p>{message}</p>
                <ButtonWrapper>
                    <Button to="/login">Войти</Button>
                    <Button to="/register">Создать аккаунт</Button>
                </ButtonWrapper>
            </PopupContent>
        </PopupContainer>
    );
};

export default AuthPopup;