import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface LoginPayload {
    identifier: string;
    password: string;
}

interface LoginResponse {
    user: {
        id: string;
        username: string;
        phoneNumber: string;
        photo_url?: string | null;
    };
    token: string;
}

export const login = createAsyncThunk<LoginResponse, LoginPayload>(
    'auth/login',
    async ({ identifier, password }, { rejectWithValue }) => {
        try {
            const response = await axios.post<LoginResponse>('https://api.verdict.bot/auth/login', { identifier, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.error) {
                return rejectWithValue({
                    status: error.response.status,
                    statusText: error.response.statusText,
                    data: error.response.data,
                });
            } else {
                return rejectWithValue({
                    status: 500,
                    statusText: 'Internal Server Error',
                    data: { error: 'Произошла неизвестная ошибка' },
                });
            }
        }
    }
);

interface RegisterPayload {
    username: string;
    phoneNumber: string;
    password: string;
    promocode: string;
}

interface RegisterResponse {
    user: {
        id: string;
        username: string;
        phoneNumber: string;
        photo_url?: string | null;
    };
    token: string;
}

export const register = createAsyncThunk<RegisterResponse, RegisterPayload>(
    'auth/register',
    async ({ username, phoneNumber, password, promocode }, { rejectWithValue }) => {
        try {
            const response = await axios.post<RegisterResponse>('https://api.verdict.bot/auth/register', { 
                username, 
                phoneNumber, 
                password, 
                promocode 
            });
            console.log(response);

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            return response.data;
        } catch (error: any) {
            console.log('Ошибка при регистрации:', error);
            console.log('Ошибка при регистрации, response:', error.response);
            
            if (error.response && error.response.data && error.response.data.error) {
                return rejectWithValue({
                    status: error.response.status,
                    statusText: error.response.statusText,
                    data: error.response.data,
                });
            } else {
                return rejectWithValue({
                    status: 500,
                    statusText: 'Internal Server Error',
                    data: { error: 'Произошла неизвестная ошибка' },
                });
            }
        }
    }
);

export const performLogout = createAsyncThunk(
    'auth/logout',
    async (_, { dispatch }) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        dispatch({ type: 'auth/resetState' });
    }
);

interface TelegramLoginPayload {
    token: string;
    username: string;
    photo_url?: string | null;
    user_id: string;
}

interface TelegramLoginResponse {
    user: {
        id: string;
        username: string;
        photo_url?: string | null;
        telegram_id: string;
    };
    token: string;
}

export const telegramLogin = createAsyncThunk<TelegramLoginResponse, TelegramLoginPayload>(
    'auth/telegramLogin',
    async ({ token, username, photo_url, user_id }, { rejectWithValue }) => {
        try {
            localStorage.setItem('token', token);
            const user = { id: user_id, username, photo_url, telegram_id: user_id };
            localStorage.setItem('user', JSON.stringify(user));
            return { user, token };
        } catch (err) {
            if (err instanceof Error) {
                return rejectWithValue(err.message);
            }
            return rejectWithValue('An unknown error occurred');
        }
    }
);